<template>
  <div>
    <crud-button :onClickList="fetchSatisPersonel" :onClickAddNew="addNew" />
    <b-card v-if="getSatisPersonels" no-body class="card-company-table">
      <b-table
        :items="getSatisPersonels"
        responsive
        show-empty
        empty-text="Kayıt bulunamadı"
        :fields="tableColumns"
        class="mb-0"
      >
        <template #cell(actions)="data">
          <div class="text-right">
            <!-- Update -->
            <feather-icon
              :id="`user-row-${data.item.id}-edit-icon`"
              @click="
                $router.push({
                  name: 'manage-satispersonel-save',
                  params: { id: data.item.id },
                })
              "
              icon="EditIcon"
              class="cursor-pointer text-primary"
              size="16"
            />

            <!-- Remove -->
            <feather-icon
              :id="`user-row-${data.item.id}-remove-icon`"
              icon="Trash2Icon"
              @click="removeSatisPersonel(data.item.id)"
              class="cursor-pointer mx-1 text-danger"
              size="16"
            />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import useSatisPersonel from './useSatisPersonel';

export default {
  methods: {
    addNew() {
      this.$router.push({ name: 'manage-satispersonel-save', params: { id: -1 } });
    },
    removeSatisPersonel(id) {
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('satisPersonelYonetim/removeSatisPersonel', id)
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: response.message },
              })
              this.fetchSatisPersonel();
            } else {
              AlertService.error(this, response.message)
            }
          })
      })
    },
  },
  setup() {
    const {
      fetchSatisPersonel,
      getSatisPersonels,
      tableColumns,
      perPage,
      currentPage,
      toplamSatisPersonel,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      SatisPersonelTable,
    } = useSatisPersonel()

    return {
      fetchSatisPersonel,
      getSatisPersonels,
      tableColumns,
      perPage,
      currentPage,
      toplamSatisPersonel,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      SatisPersonelTable,
    }
  },
};

</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
