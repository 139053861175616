import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function useSatisPersonel() {
  const SatisPersonelTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'Kod', key: 'sKodu' },
    { label: 'Ad', key: 'sAdi' },
    { label: 'Soyad', key: 'sSoyadi' },
    { label: 'Telefon', key: 'sTelefon' },
    { label: 'Aksiyonlar', key: 'actions', class: 'text-right' },
  ]

  const perPage = ref(10)
  const toplamSatisPersonel = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters.getMusteriLocalFilters.sortBy
    },
    set(value) {
      store.commit('yonetimMusteri/setMusteriFilter', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters.getMusteriLocalFilters.sortDesc
    },
    set(value) {
      store.commit('yonetimMusteri/setMusteriFilter', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = SatisPersonelTable.value ? SatisPersonelTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamSatisPersonel.value,
    }
  })

  const refetchData = () => {
    SatisPersonelTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchSatisPersonel = () => {
    store.dispatch('satisPersonelYonetim/fetchSatisPersonels', {})
  }

  const getSatisPersonels = computed(() => {
    const satispersonels = store.getters['satisPersonelYonetim/getSatisPersonels'];
    toplamSatisPersonel.value = satispersonels.length
    return satispersonels
  })

  onMounted(fetchSatisPersonel)

  return {
    fetchSatisPersonel,
    getSatisPersonels,
    tableColumns,
    perPage,
    currentPage,
    toplamSatisPersonel,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    SatisPersonelTable,
  }
}
